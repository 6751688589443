import React from 'react'
import Footer from './Footer'
import StickyFooter from './Stickyfooter'

const MainFooter = () => {
  return (
    <>
    <Footer />
    <StickyFooter />
    </>
  )
}

export default MainFooter