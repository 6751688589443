import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./aboutTop.css";
import { db } from "../../../firebase";
import { collection, doc, getDocs } from "firebase/firestore";

const AboutTop = () => {
  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "about"));
      querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
      });
      setMenuData(querySnapshot.docs.map((doc) => doc.data()));
      console.log(menuData);
    };

    fetchData();
  }, []);
  return (
    <>
      <Container className="top-container" fluid>
        <Row className="ul-row1">
          <div class="square">
            <h2 className="w3-leftbar w3-border-bottom w3-border-grey certification">
              {" "}
              {menuData[0]?.title}
            </h2>
            <div className="my-video">
              <video width="100%" height="auto" controls autoPlay muted loop>
                <source src="video/vdo1.mp4" type="video/mp4" />
              </video>
              <h5> {menuData[0]?.tag}</h5>
            </div>
            <p className="my-para">{menuData[0]?.des}</p>
            {/* <button className="btn-read">Read more</button> */}
          </div>
        </Row>
      </Container>
    </>
  );
};
export default AboutTop;
