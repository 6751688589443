import { useState, useEffect, useCallback } from "react";
import Navbar from "react-bootstrap/Navbar";
import "./header.css";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { NavLink } from "react-router-dom";

function Header() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "nav"));
      querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
      });
      setMenuData(querySnapshot.docs.map((doc) => doc.data()));
      console.log(menuData);
    };
    fetchData();
  }, []);

  return (
    <>
      <Navbar id="nav" className="navbar">
        <Navbar.Brand>
          <img className="img-logo" src={menuData[0]?.img} alt="" />
          <span className="brand-name"> {menuData[0]?.website}</span>
        </Navbar.Brand>
        <div className="">
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink className="nav-links" activeClassName="active" to="/">
                {menuData[0]?.menu1}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-links"
                activeClassName="active"
                to="/about"
              >
                {menuData[0]?.menu2}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-links"
                activeClassName="active"
                to="/products"
              >
                {menuData[0]?.menu3}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-links"
                activeClassName="active"
                to="/certification"
              >
                {menuData[0]?.menu4}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-links"
                activeClassName="active"
                to="/socially-responsive"
              >
                {menuData[0]?.menu5}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-links"
                activeClassName="active"
                to="/contact"
              >
                {menuData[0]?.menu6}
              </NavLink>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
          </div>
        </div>
      </Navbar>
    </>
  );
}

export default Header;
